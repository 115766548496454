<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1>注册会员</h1>
              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="名字"
                  autocomplete="username"
                  v-model="username"
                  @keyup="handleInput"
                />
              </b-input-group>

              <!-- <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>@</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="账号"
                  v-model="email"
                  @keyup="handleInput"
                />
              </b-input-group> -->

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  placeholder="密码"
                  autocomplete="new-password"
                  v-model="password"
                />
              </b-input-group>

              <b-input-group class="mb-4">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  placeholder="密码确认"
                  autocomplete="new-password"
                  v-model="passwordConfirm"
                />
              </b-input-group>

              <b-input-group class="mt-4">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-tag"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="推荐号码"
                  autocomplete="recommend-code"
                  v-model="recommendCode"
                />
              </b-input-group>
              <b-button class="mt-3" @click.prevent="register" variant="primary" block>生成新账号</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'Register',
  data() {
    return {
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      name: '',
      recommendCode: '',
      error: null,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.remove('bg-white')
  },
  methods: {
    handleInput() {
      this.username = this.username.replace(/[^a-z0-9]/gi, '')
    },
    async register() {
      try {
        if (this.password != this.passwordConfirm) {
          alert('wrong password')
          return
        }
        //this.showSpinner()
        const response = await AuthService.register({
          username: this.username,
          password: this.password,
          recommendCode: this.recommendCode,
        })
        alert('注册会员成功')
        this.$router.push({
          name: 'Home',
        })

        console.log(response)
        //this.hideSpinner()
        this.$cookie.set('redboysms_token', response.data.token)
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
      } catch (err) {
        this.hideSpinner()
        this.error = err.response.data.error
      }
    },
  },
}
</script>
